import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FooterComponent} from "../../layout/footer/footer.component";
import {APIService} from "../../services/api.service";
import {NgOptimizedImage} from "@angular/common";
import {DialogService} from "../../services/dialog.service";
import {AuthService} from "../../services/auth.service";
import {ModalService} from "../../services/modal.service";
import {IMenuButtonItem, MenuButtonComponent} from "../../../oex-ui-kit/components/menu-button/menu-button.component";
import {UploadInputComponent} from "../../../oex-ui-kit/components/upload-input/upload-input.component";

@Component({
  selector: 'st-home-page',
  standalone: true,
  imports: [
    RouterLink,
    FooterComponent,
    NgOptimizedImage,
    MenuButtonComponent,
    UploadInputComponent
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit {
  constructor(private api: APIService,
              public auth: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private modal: ModalService,
              private ds: DialogService) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('form') === '1') {
      this.onApplyClick();
    }
  }

  /* test() {

     if (localStorage.getItem('test') === '1') {
       console.log('HOME: auth');
       void this.api.doOAuthLogin('F4571018-CB93-11EE-BAB0-2605FA821D90');
     }
     console.log('HOME: state');
     void this.api.getState();
   }*/

  onApplyClick() {
    if (!this.auth.isLogged) {
      this.ds.showUnAuthDialog(location.href + '?form=1');
      return;
    }

    if (this.auth.user?.startup?.slug) {
      // void this.router.navigateByUrl(`/portal/edit/${this.auth.user?.startup?.slug}`);
      void this.router.navigateByUrl(`/portal`);
      return;
    }

    void this.modal.show({
      component: import('./../sign-up-page/sign-up-page.component')
    });
  }
}
