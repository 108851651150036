<section>
  <div class="ft-fin">
    <a href="https://www.intersystems.com/industries/business/financial-services/">
      Finance
      <!--        <p>Proven speed, scalability and reliability</p>-->
    </a>

    <a href="https://www.intersystems.com/industries/healthcare-technology/">
      Health & Life Sciences
      <!--        <p>Advanced software for healthcare, powered by interoperability</p>-->
    </a>

    <a href="https://www.intersystems.com/industries/business/">
      Business
      <!--        <p>Turning data into sound business decisions</p>-->
    </a>

    <a href="https://www.intersystems.com/industries/government/">
      Government
      <!--        <p>Helping governments serve their citizens</p>-->
    </a>
  </div>
  <div class="ft-iris-data">
    <a href="https://www.intersystems.com/products/intersystems-iris/">
      InterSystems IRIS Data Platform
      <!--        <p>Data Platform for Transactions & Analytics</p>-->
    </a>

    <a href="https://www.intersystems.com/products/intersystems-iris-for-health/">
      InterSystems IRIS for Health
      <!--        <p>Data Platform for Healthcare Solutions</p>-->
    </a>

    <a href="https://www.intersystems.com/products/healthshare/">
      HealthShare
      <!--        <p>Unified Care Record</p>-->
    </a>

    <a href="https://www.intersystems.com/products/trakcare/">
      TrakCare
      <!--        <p>Unified Healthcare Information System</p>-->
    </a>

    <a href="https://www.intersystems.com/products/cache/">
      Caché
      <!--        <p>High-Performance Database</p>-->
    </a>

    <a href="https://www.intersystems.com/products/ensemble/">
      Ensemble
      <!--        <p>Integration Engine</p>-->
    </a>
  </div>
  <div class="ft-contact">
    <a href="https://www.intersystems.com/who-we-are/contact-us/">
      Contact us
    </a>
    <a href="https://www.intersystems.com/news-events/intersystems-blogs/">
      InterSystems Blogs
    </a>
    <a href="https://www.intersystems.com/news-events/events/">
      Events
    </a>
    <a href="https://isc-partnering.ideas.aha.io/">
      Share your ideas
    </a>
    <a href="https://discord.com/channels/698987699406766170/863351145896214528" target="_blank" rel="noopener noreferrer">
      <img src="assets/img/discord-button.svg">
    </a>
  </div>
  <div class="ft-partner">
    <a href="/services">
      Find a Partner
    </a>
    <!--<a onclick="window['oexDialogService'].showNewCompanyDialog(true)">
      Become a Partner
    </a>-->
    <a href="/solutions">
      Find a Product
    </a>
    <a href="/technology-partners-listing">
      For technology partners
    </a>
  </div>
  <div class="ft-help">
    <a href="https://www.intersystems.com/support-learning/support/immediate-help/">
      For Immediate Help
    </a>
    <a href="https://www.intersystems.com/support-learning/learning-services/">
      Learning Services
    </a>
    <a href="https://github.com/intersystems-community/openexchange">
      Report an issue
    </a>
  </div>
</section>

<!-- Footer -->
<div class="bottom-section">
  <span>© {{currentYear}} InterSystems Corporation, Cambridge, MA. All rights reserved.</span>
  <a href="https://www.intersystems.com/privacy-policy/">Privacy & Terms</a>
  <a href="https://www.intersystems.com/guarantee/">Guarantee</a>
  <a href="https://www.intersystems.com/section-508/">Section 508</a>
  <a href="/markdown?url=/assets/doc/contest-terms.md">Contest Terms</a>
  <!--<div (click)="showOneTrustSettings()"><a>Cookies Settings</a></div>-->
</div>


<!--
<div class="content">
  <div>
    <div class="ft-fin">
      <a href="https://www.intersystems.com/industries/business/financial-services/">
        Finance
        &lt;!&ndash;        <p>Proven speed, scalability and reliability</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/industries/healthcare-technology/">
        Health & Life Sciences
        &lt;!&ndash;        <p>Advanced software for healthcare, powered by interoperability</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/industries/business/">
        Business
        &lt;!&ndash;        <p>Turning data into sound business decisions</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/industries/government/">
        Government
        &lt;!&ndash;        <p>Helping governments serve their citizens</p>&ndash;&gt;
      </a>
    </div>
    <div class="ft-iris-data">
      <a href="https://www.intersystems.com/products/intersystems-iris/">
        InterSystems IRIS Data Platform
        &lt;!&ndash;        <p>Data Platform for Transactions & Analytics</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/products/intersystems-iris-for-health/">
        InterSystems IRIS for Health
        &lt;!&ndash;        <p>Data Platform for Healthcare Solutions</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/products/healthshare/">
        HealthShare
        &lt;!&ndash;        <p>Unified Care Record</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/products/trakcare/">
        TrakCare
        &lt;!&ndash;        <p>Unified Healthcare Information System</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/products/cache/">
        Caché
        &lt;!&ndash;        <p>High-Performance Database</p>&ndash;&gt;
      </a>

      <a href="https://www.intersystems.com/products/ensemble/">
        Ensemble
        &lt;!&ndash;        <p>Integration Engine</p>&ndash;&gt;
      </a>
    </div>
    <div class="ft-contact">
      <a href="https://www.intersystems.com/who-we-are/contact-us/">
        Contact us
      </a>
      <a href="https://www.intersystems.com/news-events/intersystems-blogs/">
        InterSystems Blogs
      </a>
      <a href="https://www.intersystems.com/news-events/events/">
        Events
      </a>
      <a href="https://isc-partnering.ideas.aha.io/">
        Share your ideas
      </a>
      <a href="https://discord.com/channels/698987699406766170/863351145896214528" target="_blank" rel="noopener noreferrer">
        <img src="/assets/img/discord-button.svg">
      </a>
    </div>
    <div class="ft-partner">
      <a href="/services">
        Find a Partner
      </a>
      <a onclick="window['oexDialogService'].showNewCompanyDialog(true)">
        Become a Partner
      </a>
      <a href="/solutions">
        Find a Product
      </a>
      <a href="/technology-partners-listing">
        For technology partners
      </a>
    </div>
    <div class="ft-help">
      <a href="https://www.intersystems.com/support-learning/support/immediate-help/">
        For Immediate Help
      </a>
      <a href="https://www.intersystems.com/support-learning/learning-services/">
        Learning Services
      </a>
      <a href="https://github.com/intersystems-community/openexchange">
        Report an issue
      </a>
    </div>
  </div>

  &lt;!&ndash; Footer &ndash;&gt;
  <section class="bottom-section">
    <div>
      <span>© {{currentYear}} InterSystems Corporation, Cambridge, MA. All rights reserved.</span>
      <a href="https://www.intersystems.com/privacy-policy/">Privacy & Terms</a>
      <a href="https://www.intersystems.com/guarantee/">Guarantee</a>
      <a href="https://www.intersystems.com/section-508/">Section 508</a>
      &lt;!&ndash;<a href="/markdown?url=/assets/doc/contest-terms.md">Contest Terms</a>&ndash;&gt;
      &lt;!&ndash;<div (click)="showOneTrustSettings()"><a>Cookies Settings</a></div>&ndash;&gt;
    </div>
  </section>
</div>
-->



<!--
<div class="content">
  <section>
    1
  </section>
  <section>
    2
  </section>
  <section>
    3
  </section>
  <section>
    4
  </section>
  <section>
    5
  </section>
</div>

<div class="bottom-section">
  © 2024 InterSystems Corporation, Cambridge, MA. All rights reserved.
</div>
-->
