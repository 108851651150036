import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AsyncPipe, NgComponentOutlet, NgIf} from "@angular/common";
import {IModalButton, IModalData, ModalService} from "../../services/modal.service";

@Component({
  selector: 'st-modal',
  standalone: true,
  imports: [
    NgComponentOutlet,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements AfterViewInit {
  @ViewChild(NgComponentOutlet, {static: true}) ngComponentOutlet?: NgComponentOutlet;
  @Input() data: IModalData|null = null;

  constructor(private modal: ModalService) {
  }

  ngAfterViewInit() {
    if (this.ngComponentOutlet && this.ngComponentOutlet['_componentRef']?.instance) {
      this.ngComponentOutlet['_componentRef'].instance._modal = this;
    }
  }

  onButtonClick(btn: IModalButton, e: MouseEvent) {
    if (btn.click) {
      btn.click(btn, e);
    }
    if (btn.close && this.data) {
      this.modal.close(this.data);
    }
  }

  close() {
    if (!this.data) {
      return;
    }
    this.modal.close(this.data);
  }
}
