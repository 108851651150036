import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from "@angular/router";
import {IStartupComment, IStartupData, IStartupStatusesResponse, IStartupStatusTrack} from "./api.types";
import {inject} from "@angular/core";
import {APIService} from "./api.service";
import {of} from "rxjs";
import {AuthService} from './auth.service';

export const resolveStartupData: ResolveFn<IStartupData> = (route: ActivatedRouteSnapshot) => {
  const slug = route.paramMap.get('startup');
  const api = inject(APIService);
  if (slug) {
    return api.getStartup(slug)
      .then(data => {
        parseAddress(data);
        return data;
      });
  }
  return of({} as IStartupData);
}

export const resolveStartupTracks: ResolveFn<IStartupStatusTrack[]> = (route: ActivatedRouteSnapshot) => {
  const slug = route.paramMap.get('startup');
  const api = inject(APIService);
  if (slug) {
    return api.getStartupStatuses(slug)
      .then(resp => {
        return resp.tracks;
      });
  }
  return of([]);
}

export const resolveStartupComments: ResolveFn<IStartupComment[]> = (route: ActivatedRouteSnapshot) => {
  const slug = route.paramMap.get('startup');
  const api = inject(APIService);
  const auth = inject(AuthService);
  if (!slug || (!auth.isAdmin && !auth.isManager && !auth.isSales)) {
    return of([]);
  }
  return api.getStartupComments(slug);
  //return of([{id: 1, text: 'Test **message**'}, {id: 2, text: 'Test **message**2'}]);
}


function parseAddress(data: IStartupData) {
  if (!data.address) {
    return;
  }

  const a = data.address;
  a.text = ((a.line1 || '') + ' - ' + (a.line2 || '')) || '';
}
