import {APP_INITIALIZER, ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import { provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import {StartupService} from "./services/startup.service";
import {httpInterceptor} from "./services/http-interceptor";
import {provideClientHydration} from "@angular/platform-browser";
import {provideAnimations} from "@angular/platform-browser/animations";
import {STORAGE_TOKEN, UIStorageService} from '../oex-ui-kit/services/storage.service';
import {StorageService} from './services/storage.service';
import {DatePipe} from '@angular/common';

export const initializeApplication = (ss: StartupService) => {
  return async () => {
    await ss.initialize();
  };
};

/*const scrollConfig: InMemoryScrollingOptions = {
  anchorScrolling: 'disabled',
  scrollPositionRestoration: 'disabled'
};*/

export const appConfig: ApplicationConfig = {
  providers: [
    DatePipe,
    //{provide: APP_SSR_COOKIES, useValue: ''},
    provideHttpClient(...(typeof window === 'undefined' ? [] : [withFetch()]), withInterceptors([httpInterceptor])),
    provideClientHydration(),
    provideAnimations(),
    provideRouter(
      routes,
      // withInMemoryScrolling(scrollConfig)
      // withDebugTracing()
      // withPreloading(PreloadAllModules)
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      multi: true,
      deps: [StartupService],
    },
    { provide: STORAGE_TOKEN, useClass: StorageService }
  ]
};
