import {ResolveFn, Router} from "@angular/router";
import {ISidebarItem} from "../../oex-ui-kit/components/sidebar/sidebar.component";
import {inject} from "@angular/core";
import {AuthService} from "./auth.service";
import {APIService} from "./api.service";

export const adminSidebarItems: ResolveFn<ISidebarItem[]> = () => {
  const auth = inject(AuthService);
  const api = inject(APIService);
  const router = inject(Router);

  const venture: ISidebarItem = {label: 'Venture fund', url: 'track/venture-fund', exact: false};
  const growth: ISidebarItem = {label: 'Growth toolkit', url: 'track/growth-toolkit'};
  const incubator: ISidebarItem = {label: 'Incubator', url: 'track/incubator'};
  const logOutItem: ISidebarItem =  {
    label: 'Log out', separator: true, style: 'link', click: () => {
      auth.logout().then(() => {
        void router.navigateByUrl('/');
      })
    }
  };

  let items: ISidebarItem[] = [];
  if (auth.isSales && !auth.isAdmin && !auth.isManager) {
    items = [
      {
        label: 'Tracks', children: [
          // {label: 'Grant process', url: 'tracks/grant'},
          venture,
          growth,
          incubator,
        ]
      },
      {label: 'My Profile', url: 'profile'},
      logOutItem
    ];
  } else {
    items = [
      {label: 'Overview', url: 'overview'},
      {label: 'Startups', url: 'startups'},
      {
        label: 'Tracks', children: [
          // {label: 'Grant process', url: 'tracks/grant'},
          venture,
          growth,
          incubator,
        ]
      },
      {label: 'Interviews', url: 'interviews'},
      {
        label: 'Toolkit requests', children: [
          {label: 'Benefits', url: 'requests/benefits'},
          {label: 'All requests', url: 'track/all'},
          {label: 'Requests by benefit', url: 'track/by-benefit'},
          {label: 'Requests by startup', url: 'track/by-startup'},
        ]
      },
      {label: 'Process', url: 'process'},
      {label: 'Newsletter', url: 'newsletter'},
      ...(auth.isAdmin ? [{label: 'User management', url: 'users'}] : []),
      {label: 'My Profile', url: 'profile'},
      logOutItem
    ];
  }

  //return of(items);
  return api.getTracks()
    .then(resp => {
      venture.counter = resp.track.find(t => t.slug === 'venture-fund')?.startup_qt?.toString() || '';
      incubator.counter = resp.track.find(t => t.slug === 'incubator')?.startup_qt?.toString() || '';
      growth.counter = resp.track.find(t => t.slug === 'growth-toolkit')?.startup_qt?.toString() || '';

      return items;
    });
}
