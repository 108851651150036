import {Injectable} from '@angular/core';
import {APIService} from "./api.service";
import {AuthService} from "./auth.service";
import {ToastUIEditorComponent, TUI_TOOLBAR} from "../../oex-ui-kit/components/toastui-editor/toastui-editor.component";

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  constructor(private api: APIService,
              private auth: AuthService) {
  }

  async initialize() {
    // Change TUI toolbar
    ToastUIEditorComponent.changeToolbar(
      ['heading', 'bold', 'italic', 'strike'],
      ['hr', 'quote'],
      ['ul', 'ol', 'indent', 'outdent'],
      ['link']
    );
    ToastUIEditorComponent.defaultUploadEndpoint = this.api.getEditorImageUploadEndpoint();
    try {
      await Promise.all([
        this.auth.initializeUserState()
      ])
    } catch (e) {
      console.error(e);
    }
    return Promise.resolve();
  }
}
