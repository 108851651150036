import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding, Inject,
  Input,
  OnChanges, OnInit, PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {BreadcrumbsComponent, IBreadcrumb} from '../breadcrumbs/breadcrumbs.component';


export type SidebarItemStyle = 'link' | 'link-underline';

export interface ISidebarItem {
  label: string; // Sidebar item text
  counter?: string; // Display counter near label
  icon?: string; // Image icon
  url?: string; // Internal url for routerLink
  urlExt?: string; // External url, opened in new tab
  children?: ISidebarItem[]; // Child items
  expanded?: boolean; // Is item expanded
  target?: boolean; // Target attribute for external links
  image?: string; // Image for item
  exact?: boolean; // Use exact url matching for item highlighting
  click?: (e: MouseEvent) => void; // Click event handler
  childActive?: boolean; // Is child active(used for highlighting)
  // childRoutes?: string[]; // Can be defined for highlighting
  separator?: boolean; // Shows line separator above item
  style?: SidebarItemStyle; // Style of item
}

export const ENTER_ANIMATION = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({height: 0}),
      animate('200ms', style({height: '*'}))
    ]),
    transition(':leave', [
      style({height: '*'}),
      animate('200ms', style({height: 0}))
    ])
  ],
);

export const collapseExpandAnimation = trigger('collapseExpand', [
  state('collapsed', style({
    width: '0',
    "margin-right": '0'
  })),
  state('expanded', style({
    width: '*',
    "margin-right": '*',
  })),
  transition('collapsed => expanded', [
    animate('300ms ease-in-out')
  ]),
  transition('expanded => collapsed', [
    animate('300ms ease-in-out')
  ])
]);

@Component({
  selector: 'ui-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    BreadcrumbsComponent
  ],
  styleUrls: ['./sidebar.component.scss'],
  animations: [ENTER_ANIMATION, collapseExpandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() relativeUrl = '';
  @Input() items: ISidebarItem[] = [];
  @Input() width = 306;
  isCollapsed = false;
  currentRouteTitle = '';
  currentItemTitle = '';
  isFirstInit = true;
  private routeEvents$ = this.router.events.pipe(takeUntilDestroyed());

  constructor(private router: Router,
              private cd: ChangeDetectorRef,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: Object
              ) {
    this.currentRouteTitle = this.route.snapshot.routeConfig?.title as string ?? '';
    if (isPlatformBrowser(this.platformId)) {
      this.isCollapsed = this.isMobile;
    }
  }

  @HostBinding('@collapseExpand') get collapseExpand() {
    return this.collapsed ? 'collapsed' : 'expanded';
  }

  @HostBinding('class.collapsed')
  get collapsed() {
    return this.isCollapsed;
  }

  get isMobile() {
    return window.matchMedia('(min-width: 0px) and (max-width: 736px)').matches
  }

  get mobileBreadcrumbs() {
    const parentTitle = this.route.snapshot.routeConfig?.title as string;
    const childTitle = this.route.firstChild?.snapshot?.routeConfig?.title as string;
    const childTitles: any[] = [];
    let child = this.route.firstChild;
    while (child) {
      const t = child.snapshot?.routeConfig?.title;
      if (t) {
        childTitles.push({text: t});
      }
      child = child.children[0];
    }
    const b: IBreadcrumb[] = [
      ...(parentTitle ? [{text: parentTitle}] : []),
      //...(childTitle ? [{text: childTitle}] : [])
      ...childTitles
    ];
    return b;
  }

  ngOnInit() {
    this.subscribeForNavigation();
    this.checkExpandingState();
  }

  ngAfterViewInit() {
    this.isFirstInit = false;
  }

  subscribeForNavigation() {
    this.routeEvents$.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.checkExpandingState();
        const title = this.route.firstChild?.snapshot?.routeConfig?.title;
        if ((typeof title === 'string') && (this.currentRouteTitle !== title)) {
          this.currentRouteTitle = title;
          this.cd.detectChanges();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'].currentValue !== changes['items'].previousValue) {
      this.checkExpandingState();
    }
  }

  onRowClick(item: ISidebarItem, e: MouseEvent) {
    if (item.children) {
      item.expanded = !item.expanded;
    } else {
      if (this.isMobile) {
        this.isCollapsed = true;
      }
    }

    if (item.click) {
      item.click(e);
    }
  }

  onExpand() {
    this.isCollapsed = !this.isCollapsed;
  }

  private checkExpandingState() {
    const url = this.router.url.replace(this.relativeUrl + '/', '').split('?')[0];
    // Reset child active state
    this.items.forEach(i => {
      i.childActive = false;
    });
    this.checkExpandForItems(this.items, url);
  }

  private checkExpandForItems(items?: ISidebarItem[], url = ''): boolean {
    if (!items) {
      return false;
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i].children) {
        items[i].expanded = this.checkExpandForItems(items[i].children, url);
        items[i].childActive = items[i].expanded;
      }
      /*if (items[i].url && (url.endsWith(items[i].url ?? '') || (items[i].childRoutes?.some(r => url.endsWith(r))))
      ) {
        this.currentTitle = items[i].label;
      }*!/*/
      if (items[i].url === url) {
        this.currentItemTitle = items[i].label;
        return true;
      }
    }
    return false;
  }
}
