<section class="headline">
  <!-- Title -->
  <div class="content">
    <h1>Empower Your Startup with InterSystems</h1>

    <!-- Apply now button -->
    <button class="btn-main arrow-right" (click)="onApplyClick()">
      {{auth.user.hasStartup ? 'InterSystems Startup Portal' : 'APPLY NOW'}}
    </button>

    <!-- Description -->
    <h3>The InterSystems Startup Program is a multifaceted initiative designed for<br>
      early-stage startups in healthcare, finance, and supply chain. The three main<br>
      pillars of our program include:
    </h3>

    <!-- Cards -->
    <!--<div class="card-list"></div>-->
    <div class="card-list">
      <!-- Card 1 -->
      <div class="card">
        <img ngSrc="assets/img/home/icon-venture-fund.png" height="50" width="57">
        <h2>Venture Fund</h2>
        <p>Early investments in startups that use InterSystems technology. The program offers equity investments and grants to evaluate InterSystems tech and build proofs of concept.</p>
      </div>

      <!-- Card 2 -->
      <div class="card">
        <img ngSrc="assets/img/home/icon-acceleration-programs.png" height="50" width="40" class="offset-5">
        <h2>Acceleration Programs</h2>
        <p>The 4-month program helps startups integrate InterSystems technology and prepare for funding rounds. Gain access to training, mentorship, and resources to validate your MVP and position yourself for success in competitive markets.</p>
      </div>

      <!-- Card 3 -->
      <div class="card">
        <img ngSrc="assets/img/home/icon-growth-toolkit.png" height="50" width="64" class="offset-3">
        <h2>Growth Toolkit</h2>
        <p>Low- and no-cost access to learning, technical, and market resourcing to accelerate growth and go-to-market paths.</p>
      </div>
    </div>
  </div>
</section>

<!-- InterSystems Venture Fund -->
<section class="section">
  <div>
    <h2>InterSystems Venture Fund</h2>
    <p>The Fund actively seeks out and invests in healthtech, fintech, and supply chain startups that harness the power of InterSystems technology. The program offers equity investments and grants to evaluate InterSystems tech and build proofs of concept, deliver MVPs and scale your operations.</p>
  </div>
  <img ngSrc="assets/img/home/venture-fund.jpg" height="326" width="638">
</section>


<!-- Acceleration Programs -->
<section class="section">
  <img ngSrc="assets/img/home/acceleration-programs.jpg" height="326" width="639">
  <div class="with-offset">
    <h2>Acceleration Programs</h2>
    <p>Join our acceleration program to learn how to elevate your healthcare startup to new heights.</p>
    <ul>
      <li><strong>Free Access to InterSystems Cloud Offerings:</strong> InterSystems FHIR Server, HealthShare Message Transformation Service, and InterSystems Health Connect Cloud</li>
      <li><strong>Expert Guidance:</strong> One-on-one or group sessions with mentors from InterSystems and our partner organisations</li>
      <li><strong>Learning:</strong> Workshops with top healthcare, technology, product, legal and marketing experts</li>
      <li><strong>Market Validation:</strong> Real-world product testing at a leading healthcare facility</li>
      <li><strong>Networking:</strong> Fully or partially sponsored opportunities to participate in the world's leading events and expand your network</li>
      <li><strong>Fundraising Opportunities:</strong> Events to secure initial investments from leading VC funds</li>
    </ul>
  </div>
</section>

<!-- Growth Toolkit -->
<section class="section">
  <div>
    <h2>Growth Toolkit</h2>
    <p>Apply to access a wide range of free or discounted learning, marketing, and networking opportunities.</p>
    <ul>
      <li><strong>Certification Savings:</strong> 50% discount on select InterSystems certification exam fees</li>
      <li><strong>Learning:</strong> Discounts on select InterSystems virtual classroom training courses</li>
      <li><strong>Networking:</strong> Fully or partially sponsored opportunities to participate in the world's leading events and expand your network</li>
      <li><strong>Promo Blocks on InterSystems Developer Community:</strong> Promotional posts for products, services, events or job openings</li>
      <li><strong>Google AdWords and LinkedIn Ads:</strong>  $1,000 monthly vouchers for Google AdWords and LinkedIn Ads promotional campaigns</li>
    </ul>
  </div>
  <img ngSrc="assets/img/home/growth-toolkit.jpg" height="326" width="638">
</section>

<section class="footer">
  <h2>Give your digital health solutions the best chance to succeed</h2>
  <!-- Apply now button -->
  <button class="btn-main arrow-right" (click)="onApplyClick()">
    {{auth.user.hasStartup ? 'InterSystems Startup Portal' : 'APPLY NOW'}}
  </button>
</section>

