import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  onUpdateSidebar = new EventEmitter();

  updateSidebar() {
    this.onUpdateSidebar.emit();
  }
}
