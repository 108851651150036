import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  runInInjectionContext,
  ViewChild
} from '@angular/core';
import {ISidebarItem, SidebarComponent} from "../sidebar/sidebar.component";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterLink, RouterOutlet} from "@angular/router";
import {DOCUMENT, isPlatformServer} from "@angular/common";
import {filter, take} from "rxjs";
import {ScrollVisibilityDirective} from "../../services/scroll-visibility.directive";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {STORAGE_TOKEN, UIStorageService} from '../../services/storage.service';
import {SidebarService} from '../../services/sidebar.service';

@Component({
  selector: 'ui-portal-page',
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    SidebarComponent,
    ScrollVisibilityDirective
  ],
  templateUrl: './portal-page.component.html',
  styleUrls: ['./portal-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('page') page?: ElementRef;
  @Input() items: ISidebarItem[] = [];
  relativeUrl = '';
  private updateOffsetTimeout: NodeJS.Timeout | number = 0;
  private routerEvents$ = this.router.events.pipe(takeUntilDestroyed());
  private data$ = this.route.data.pipe(takeUntilDestroyed());
  private previousUrl: string = '';

  constructor(private r2: Renderer2,
              private el: ElementRef,
              private cd: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              private ss: SidebarService,
              private zone: NgZone,
              private injector: Injector,
              @Optional() @Inject(STORAGE_TOKEN) storage: UIStorageService,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) {
    this.disableOverflow();
    this.relativeUrl = '/' + (this.route?.snapshot?.routeConfig?.path ?? '');
    this.subscribeForSidebarUpdate();
  }

  ngOnInit() {
    this.subscribeForScrollRestoration();
    this.subscribeForData();
  }

  ngAfterViewInit() {
    this.updatePortalOffset();
  }

  ngOnDestroy() {
    clearTimeout(this.updateOffsetTimeout);
    this.enableOverflow();
  }

  scrollTo(fragment = '') {
    if (!fragment) {
      this.page?.nativeElement?.scrollTo(0, 0);
    } else {
      const el = this.document.getElementById(fragment);
      setTimeout(() => {
        if (el && this.page?.nativeElement) {
          el.scrollIntoView();
        }
      });
    }
  }

  private enableOverflow() {
    this.document.body.style.overflow = 'auto';
  }

  private disableOverflow() {
    this.document.body.style.overflow = 'hidden';
    this.document.body.parentElement?.scrollTo(0, 0);
  }

  private updatePortalOffset() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const update = () => {
      const el = this.el.nativeElement;
      const y = el.children[0].getBoundingClientRect().top;
      this.r2.setProperty(el, 'style', `--portal-offset-y: ${y}px`);
    }

    this.updateOffsetTimeout = setTimeout(() => update());
  }

  private subscribeForScrollRestoration() {
    // No need to restore scroll position on server side
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.zone.runOutsideAngular(() => {

      // Subscribe to NavigationStart event to initialize previousUrl
      this.routerEvents$
        .pipe(filter(event => event instanceof NavigationStart), take(1))
        .subscribe(e => {
          this.previousUrl = (e as NavigationStart).url.split('?')[0];
        });

      // Subscribe to NavigationEnd for scroll reset
      this.routerEvents$
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event => {
          const e = event as NavigationEnd;
          // Get the base URL without query parameters
          const currentUrlBase = e.urlAfterRedirects.split('?')[0];
          if (this.previousUrl === currentUrlBase) {
            // Prevent resetting scroll position if navigating to the same route
            return;
          }
          this.scrollTo(this.route.snapshot.fragment ?? '');
          this.previousUrl = currentUrlBase;
        });
    });
  }

  private subscribeForData() {
    this.data$.subscribe(d => {
      const items = d['sidebar'];
      if (items) {
        this.items = items;
        this.cd.detectChanges();
      }
    });
  }

  private updateSidebar() {
    const sidebarResolverFn = this.route.snapshot?.routeConfig?.resolve?.sidebar;
    if (!sidebarResolverFn) {
      return;
    }
    void runInInjectionContext(this.injector, async () => {
      try {
        this.items = await sidebarResolverFn();
        this.cd.detectChanges();
      } catch (e) {
        console.error(e);
      }
    });
  }

  private subscribeForSidebarUpdate() {
    this.ss.onUpdateSidebar
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.updateSidebar();
      });
  }
}
