import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject, Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID, runInInjectionContext,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  ChildActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  ResolveFn,
  Router,
  RouterLink
} from '@angular/router';
import {SearchComponent} from "../search/search";
import {DOCUMENT, isPlatformBrowser, NgClass, NgTemplateOutlet} from "@angular/common";
import {ProgressService} from "../../services/progress.service";
import {ProgressBarComponent} from "../progress-bar/progress-bar.component";
import {AvatarComponent} from "../avatar/avatar.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {IHeaderNotification, NotificationPopupComponent} from '../notification-popup/notification-popup.component';
import {IMenuButtonAction} from '../menu-button/menu-button.component';

export type MenuItemStyle = 'link';

export interface IMenuDescItem {
  title: string;
  url?: string;
  externalUrl?: string;
  query?: any;
  click?: () => void;
  condition?: () => boolean;
  style?: MenuItemStyle;
  separator?: boolean
}

export interface IMenuDescButton {
  id: string;
  title: string;
  condition?: () => boolean;
  click?: () => void;
}

export interface IMenuColumnItem {
  title: string;
  id?: string;
  url?: string;
  query?: any;
  externalUrl?: string;
  condition?: () => boolean;
}

export interface IMenuColumnDesc {
  title: string;
  items: IMenuColumnItem[];
  isGrid?: boolean;

  // for mobile menu UI
  expanded?: boolean;
}

export interface IThirdSectionBanner {
  title: string;
  link: string;
  url?: string;
  externalUrl?: string;
  click?: () => void;
}

export interface IMenuThirdSection {
  columns: IMenuColumnDesc[];
  banner?: IThirdSectionBanner;
}

export interface IMenu {
  id: string;
  title: string;
  titleDesc?: string;
  desc?: string;
  url?: string;
  externalUrl?: string;
  items?: IMenuDescItem[];
  condition?: () => boolean;
  buttons?: IMenuDescButton[];
  showAvatar?: boolean;
  thirdSection?: IMenuThirdSection;
  hideOnMobile?: boolean;
  isIndicator?: boolean;
  query?: { [key: string]: string };

  // for mobile menu UI
  expanded?: boolean;
}

/**
 * Definition of angular component
 */
@Component({
  selector: 'ui-header',
  standalone: true,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    ProgressBarComponent,
    AvatarComponent,
    SearchComponent,
    NgClass,
    NotificationPopupComponent
  ],
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() items: IMenu[] = [];
  @Input() isLogged = false;
  @Input() url = '/';
  @Input() logo = '';
  @Input() userName = '';
  @Input() userAvatar = '';
  @Input() showSearch = false;
  @Input() showMobileMenu = true;
  @Input() profileItemName = 'profile';
  @Input() notificationsButton = false;
  @Input() notificationCount = 0;
  @Input() notificationResolver?: ResolveFn<IHeaderNotification[]>;
  // @Output() onUpdate = new EventEmitter<IMenu[]>();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onRegister = new EventEmitter<void>();
  @Output() onLogin = new EventEmitter<void>();
  @ViewChild('searchComponent') searchComponent?: SearchComponent;
  isMenu = false;
  menuDesc: IMenu[] = [];
  selSection?: IMenu;
  isProgress = false;
  isSearchFocused = false;
  isMobileSearchVisible = false;
  notifications: IMenu = {
    id: 'notifications',
    title: 'Notifications'
  }
  notificationData?: any;
  private routeEvents$ = this.router.events.pipe(takeUntilDestroyed());
  private progress$ = this.ps.isProgress.pipe(takeUntilDestroyed());

  constructor(private cdr: ChangeDetectorRef,
              public router: Router,
              private ps: ProgressService,
              private zone: NgZone,
              private injector: Injector,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  // TODO: this can be optimized calculating once when items changed
  get mobileProfileItems() {
    const sel = this.selSection;
    if (!sel) {
      return [];
    }
    return [...(sel.buttons ? [sel.buttons[0]] : []), ...(sel.thirdSection?.columns[1] ? sel.thirdSection?.columns[1]?.items : [])].filter(el => !!el);
  }

  onDocumentClick = (e: MouseEvent) => {
    const path = e.composedPath();
    if (path.some((el: any) => el.classList?.contains('second-section') ||
      el.tagName?.toLowerCase() === 'ui-notification-popup' ||
      el.classList?.contains('btn-menu-mobile') ||
      el.classList?.contains('mobile-ava-profile') ||
      el.classList?.contains('menu-mobile'))) {
      return;
    }
    if (this.selSection || this.isMenu) {
      this.selSection = undefined;
      this.isMenu = false;
      this.cdr.detectChanges();
    }
  }

  ngOnInit() {
    this.refresh();
    this.subscribe();
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
    if (this.isMenu) {
      this.selSection = undefined;
    }
    this.cdr.detectChanges();
  }

  selectSection(section?: IMenu, e?: MouseEvent) {
    if (!section) {
      return;
    }
    e?.stopPropagation();
    if (section === this.notifications) {
      this.selSection = this.selSection === this.notifications ? undefined : this.notifications;
      return;
    }
    if (section.url || section.externalUrl) {
      return;
    }
    if (this.selSection?.id === section.id) {
      this.selSection = undefined;
    } else {
      this.selSection = this.menuDesc.find(m => m.id === section.id);
    }
    this.cdr.detectChanges();
  }

  expandItem(item: IMenuDescItem | IMenu) {
    if (!(item as IMenu).items?.length) {
      return;
    }
    (item as IMenu).expanded = !(item as IMenu).expanded;
  }

  toggleMobileProfileMenu(e: MouseEvent) {
    this.selectSection(this.menuDesc.find(m => m.id === this.profileItemName), e);
    this.isMenu = false;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.removeEventListener('click', this.onDocumentClick);
    }
  }

  onSearchFocus(backdrop: HTMLElement) {
    this.isSearchFocused = true;
    backdrop.style.display = 'block';
  }

  onSearchBlur(backdrop: HTMLElement) {
    this.isSearchFocused = false;
    backdrop.style.display = 'none';
    this.isMobileSearchVisible = false;
  }

  doSearch(term: string) {
    this.onSearch.emit(term);
    this.isMobileSearchVisible = false;
    this.cdr.detectChanges();
  }

  showMobileSearch(searchComponent?: SearchComponent) {
    this.isMobileSearchVisible = true;
    if (searchComponent) {
      setTimeout(() => {
        searchComponent.setFocus();
      });
    }
  }

  /**
   * Rebuild menu. Check conditions for logged state etc.
   * @private
   */
  refresh() {
    //this.updateMenu();

    // Condition check function
    const check = (item: any) => {
      if (item.condition) {
        return item.condition();
      }
      return true;
    };

    // Check conditions
    this.menuDesc = this.items.filter(check);

    // Check condition for children items
    this.menuDesc.forEach(m => {
      if (m.buttons) {
        m.buttons = m.buttons.filter(check);
      }
      if (m.items) {
        m.items = m.items.filter(check);
      }
      if (m.thirdSection?.columns.length) {
        m.thirdSection.columns?.forEach(c => {
          if (c.items) {
            c.items = c.items.filter(check);
          }
        });
      }
    });
  }

  // Bind data to menu items, etc.
  /*private updateMenu() {
    this.onUpdate.emit(this.menuDesc);
  }*/

  onRegisterClick() {
    this.onRegister.emit();
  }

  onLoginClick() {
    this.onLogin.emit();
  }

  private subscribe() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.document.addEventListener('click', this.onDocumentClick);
      });
    }

    // Hide mobile menu & secondary menu before navigation

    this.routeEvents$.subscribe(e => {
      if ((e instanceof ChildActivationStart) || (e instanceof NavigationSkipped)) {
        this.ps.setProgress(true, true);
        this.selSection = undefined;
        this.isMenu = false;
        this.cdr.detectChanges();
      }
      if ((e instanceof NavigationEnd) || (e instanceof NavigationSkipped) || (e instanceof NavigationCancel) || (e instanceof NavigationError)) {
        if (this.searchComponent?.search?.nativeElement) {
          this.searchComponent.search.nativeElement.value = '';
        }
        this.ps.setProgress(false, true);
        this.cdr.detectChanges();
      }
    });

    // Subscribe for progress show/hide
    this.progress$.subscribe(v => {
      this.isProgress = v;
      this.cdr.detectChanges();
    });
  }

  async requestNotifications(e: MouseEvent) {
    this.selectSection(this.notifications, e);
    if (!this.selSection) {
      return;
    }
    this.notificationData = undefined;
    try {
      this.notificationData = await runInInjectionContext(this.injector, () => {
        if (!this.notificationResolver) {
          return;
        }
        this.ps.show();
        return this.notificationResolver(this.route.snapshot, this.router.routerState.snapshot);
      });
      this.cdr.detectChanges();
    } catch (e) {
      console.error(e);
    } finally {
      this.ps.hide();
    }
  }

  onNotificationPopupAction(action: IMenuButtonAction) {
    if (action.item.id === 'settings') {
      action.event?.stopPropagation();
      this.selSection = undefined;
    }
  }
}
