<div class="modal">
  <!-- Title -->
  @if (data?.title) {
    <h3>{{ data?.title }}</h3>
  }

  <!-- Message -->
  @if (data?.message) {
    <p>{{ data?.message }}</p>
  }

  <!-- Dynamic component -->
  <ng-container *ngComponentOutlet="data?.component; inputs: data?.inputs"></ng-container>

  <!-- Footer -->
  <div class="footer">
    <!-- Buttons -->
    @for (btn of data?.buttons;track (btn.id || btn.text)) {
      <button
        [class.btn-primary]="btn.primary"
        [class.btn-outline]="!btn.primary"
        (click)="onButtonClick(btn, $event)"
      >{{ btn.text }}</button>
    }
  </div>
</div>
